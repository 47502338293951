import { SuperAdminRole } from '@/config/constants';
import { getRecord, getRecords, saveRecord } from '@/services/firebase/FirestoreService.js';
import firebase from 'firebase';
import chunk from 'lodash/chunk.js';
import keyBy from 'lodash/keyBy.js';
import merge from 'lodash/merge.js';

export const namespaced = true;

export const state = {
  quarterlyEvaluations: [],
};

export const getters = {
  quarterlyEvaluations: ({ quarterlyEvaluations }) => quarterlyEvaluations,
  quarterlyEvaluation: (state) => (id) => state.quarterlyEvaluations.find((quarterlyEvaluation) => quarterlyEvaluation.id === id),
};

export const mutations = {
  SET_QUARTERLY_EVALUATIONS(state, quarterlyEvaluations) {
    state.quarterlyEvaluations = quarterlyEvaluations;
  },
};

export const actions = {
  async fetchQuarterlyEvaluations({ commit }, config = {}) {
    const idTokenResult = await firebase.auth().currentUser.getIdTokenResult(true);
    const isSuperAdmin = (idTokenResult.claims && idTokenResult.claims.role === SuperAdminRole) || false;
    if (isSuperAdmin || /*TJ*/ firebase.auth().currentUser.uid === 'uITwTNBcLag5u8vA0lErHV5n2lx1') {
      return getRecords({
        ...config,
        collectionName: 'quarterlyEvaluations',
      }).then((records) => {
        commit('SET_QUARTERLY_EVALUATIONS', records);
      });
    } else {
      const contactRecordID = idTokenResult.claims && idTokenResult.claims.contactRecord;
      // Own Records
      if (!contactRecordID) throw new Error("User doesn't have an associated contact id.");
      const firestore = firebase.firestore();
      const collectionRef = firestore.collection('quarterlyEvaluations');

      const ownRequest = collectionRef
        .where('evaluator.id', '==', contactRecordID)
        .get()
        .then((querySnapshot) => querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));

      const beingEvaluatedRequest = collectionRef
        .where('employee.id', '==', contactRecordID)
        .where('visible', '==', true)
        .get()
        .then((querySnapshot) => querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));

      // fetch subordinate list
      const minionsIDs = await getRecords({
        populations: {},
        collectionName: 'contacts',
        whereStatements: [['manager', '==', contactRecordID]],
      }).then((records) => records.map(({ id }) => id));

      const minionsRequests = minionsIDs.length
        ? chunk(minionsIDs, 10).map((c) =>
            collectionRef
              .where('evaluator.id', 'in', c)
              .where('visible', '==', true)
              .get()
              .then((querySnapshot) => querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))),
          )
        : [];
      const fetches = [ownRequest, beingEvaluatedRequest, ...minionsRequests];
      return Promise.all(fetches).then((records) => {
        const fRecords = Object.values(merge({}, ...records.map((reqRecords) => keyBy(reqRecords, 'id'))));
        commit('SET_QUARTERLY_EVALUATIONS', fRecords);
      });
    }
  },
  saveQuarterlyEvaluation(context, quarterlyEvaluationOptions) {
    return saveRecord(quarterlyEvaluationOptions).then((id) => id);
  },
  fetchQuarterlyEvaluation(context, id) {
    return getRecord({ collectionName: 'quarterlyEvaluations', id })
      .then((record) => record)
      .catch((error) => {
        return error;
      });
  },
};

export default {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
