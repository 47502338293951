<template>
  <v-container fluid>
    <!-- Modal for deleting an stored image -->
    <v-dialog v-model="deleteImageDialog" max-width="600px">
      <v-card>
        <v-card-title>{{ deleteAlertTitle }}</v-card-title>
        <v-card-text>{{ deleteAlertDescription }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text small dark color="red darken-1" @click="deleteImageDialog = false">No </v-btn>
          <v-btn
            text
            small
            dark
            color="green darken-1"
            @click="
              $emit('delete', pictureURL);
              pictureURL = null;
              deleteImageDialog = false;
            "
            >Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-row>
      <v-col cols="12" class="pb-0">
        <label class="v-label theme--light" v-if="label">{{ label }}<span v-if="required" class="red--text">*</span></label>

        <v-row v-if="existingFiles && existingFiles.length > 0">
          <v-col cols="12" sm="6" v-for="file in existingFiles" :key="file.url">
            <!-- Image component -->
            <!-- Temporary v-if to skip the files from google drive,
              currently figuring out a solution to show those as well -->
            <le-file
              v-if="file.url.split('&export=download').length === 1"
              :file="file"
              @delete="
                pictureURL = file.url;
                deleteImageDialog = true;
              "
              :readonly="isDisabled"
            >
            </le-file>
          </v-col>
        </v-row>

        <br />
        <!-- File input will be hidden, instead, it will be triggered -->
        <!-- By another nice looking button -->
        <!-- Resource: https://www.youtube.com/watch?v=J2Wp4_XRsWc -->
        <input
          type="file"
          style="display: none"
          :accept="allowedMimeTypes.join(',')"
          :disabled="isDisabled"
          @change="handleFileSelection($event)"
          ref="fileInput"
        />
        <v-btn color="success" class="my-5" small text @click="$refs['fileInput'].click()" :disabled="isDisabled"
          >Add file <v-icon dark>mdi-paperclip</v-icon>
        </v-btn>

        <p class="mb-0 black--text" v-if="message">{{ message }}</p>
        <p v-if="errorMessages && errorMessages.length" class="error--text">
          <span class="d-block" v-for="error in errorMessages" :key="error">{{ error }}</span>
        </p>
      </v-col>
    </v-row>

    <v-row>
      <v-col v-if="localFiles.length > 0" class="pb-0">
        <v-chip v-for="(picture, index) in localFiles" :key="picture.fileName" close small @click:close="removeGroupFile(index)">{{
          picture.fileName
        }}</v-chip>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { getDataFromFileEvent } from '@/services/utils/Utils.js';

const types = {
  images: {
    png: 'image/png',
    gif: 'image/gif',
    jpg: 'image/jpeg',
    jpeg: 'image/jpeg',
    heic: 'image/heic',
    svg: 'image/svg+xml',
  },
  videos: {
    mp4: 'video/mp4',
  },
  pdfs: {
    pdf: 'application/pdf',
  },
  docs: {
    doc: 'application/msword',
    docs: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  },
  excels: {
    xls: 'application/vnd.ms-excel',
    xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    xlsm: 'application/vnd.ms-excel.sheet.macroEnabled.12',
  },
  cads: {
    cad: '.cad',
    dwg: '.dwg',
  },
  zips: {
    zip: 'application/zip',
  },
};

export default {
  data: () => ({
    deleteImageDialog: false,
    pictureURL: null,
  }),
  model: {
    prop: 'localFiles',
    event: 'change',
  },
  inject: {
    form: {
      name: 'form',
      default: null,
    },
  },
  computed: {
    allowedExtensions: function () {
      return this.allowedFileTypes
        .map((type) => types[type] || {})
        .reduce((extensions, typeExtensions) => extensions.concat(Object.keys(typeExtensions)), []);
    },
    allowedMimeTypes: function () {
      return this.allowedFileTypes
        .map((type) => types[type] || {})
        .reduce((extensions, typeExtensions) => extensions.concat(Object.values(typeExtensions)), []);
    },
    typeByExt: function () {
      return Object.entries(types).reduce((typesByExt, [type, extensions]) => {
        Object.keys(extensions).forEach((ext) => (typesByExt[ext] = type));
        return typesByExt;
      }, {});
    },
    isDisabled: function () {
      return this.disabled || (this.form?.disabled ?? false);
    },
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    message: {
      type: String,
      default: '',
    },
    errorMessages: {
      type: Array,
      default() {
        return [];
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    existingFiles: {
      type: Array,
      required: true,
      default() {
        return [];
      },
    },
    localFiles: {
      type: Array,
      required: true,
      default() {
        return [];
      },
    },
    allowedFileTypes: {
      type: Array,
      required: false,
      default() {
        return ['images'];
      },
    },
    deleteAlertTitle: {
      type: String,
      default: 'Delete Image',
    },
    deleteAlertDescription: {
      type: String,
      default: 'Are you sure you want to permanently delete this image?',
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    getDataFromFileEvent,

    removeGroupFile(index) {
      const files = this.localFiles;
      files.splice(index, 1);
      this.$emit('change', files);
    },

    // Handler for Picture Groups
    handleFileSelection(event) {
      if (!event.target.files.length) return;
      this.getDataFromFileEvent(event, this.allowedExtensions)
        .then((file) => {
          file.mimetype = file.file.type;
          file.type = this.typeByExt[file.fileName.split('.').pop()] || 'Unknown';
          this.$emit('change', [...this.localFiles, file]);
        })
        .catch((error) => {
          console.log(error);
          this.$refs['fileInput'].value = '';
          if (error.hasOwnProperty('type') && error.type === 'Unsupported Extension') {
            swal(
              'Warning',
              `Only the formats "${this.allowedExtensions.map((ext) => ext.toUpperCase()).join('", "')}" are allowed.`,
              'warning',
            );
          } else if (error.error) {
            swal('Warning', error.error, 'warning');
          } else {
            swal(
              'Error',
              "Something went wrong when attaching an image, please try again or contact with LightEdison's IT Department",
              'error',
            );
          }
        });
    },
  },
};
</script>

<style></style>
