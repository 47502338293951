// Use Cases
import CreateLeaveRequestUseCase from '@/core/features/leave-requests/use-cases/createLeaveRequest';
import UpdateLeaveRequestUseCase from '@/core/features/leave-requests/use-cases/updateLeaveRequest';
import GetLeaveRequestUseCase from '@/core/features/leave-requests/use-cases/getLeaveRequest';
import GetLeaveRequestsUseCase from '@/core/features/leave-requests/use-cases/getLeaveRequests';
import GetLeaveRangeUseCase from '@/core/features/leave-requests/use-cases/getLeaveRange';
import VerifyLeaveRequestUseCase from '@/core/features/leave-requests/use-cases/verifyLeaveRequest';

// Entities, repositories and Interfaces
import LeaveRequestRepository from '@/core/features/leave-requests/interfaces/LeaveRequestRepository';
import LeaveTimeEntriesRepository from '@/core/features/leave-time-entries/interfaces/LeaveTimeEntriesRepository';
import ContactsRepository from '@/core/features/contacts/interfaces/ContactsRepository';
import AuthenticationGateway from '@/core/features/auth/interfaces/AuthenticationGateway';
import HolidaysRepository from '@/core/features/leave-requests/interfaces/HolidaysRepository';
import LeaveRequest from '@/core/entities/LeaveRequest';
import CalendarAdapter from '@/core/interfaces/adapters/CalendarAdapter';

// Implementations
import LeaveRequestsRepositoryFirestoreImpl from '@/infrastructure/data-providers/leave-requests/LeaveRequestsRepositoryFirestoreImpl';
import ContactsRepositoryFirestoreImpl from '@/infrastructure/data-providers/contacts/ContactsRepositoryFirestoreImpl';
import AuthenticationGatewayFirebaseImpl from '@/infrastructure/auth-providers/firebase/AuthenticationGatewayFirebaseImpl';
import HolidaysRepositoryInMemoryImpl from '@/infrastructure/data-providers/holidays/HolidaysRepositoryInMemoryImpl';
import CalendarAdapterGoogleImpl from '@/infrastructure/adapters/CalendarAdapterGoogleImpl';
import LeaveTimeEntriesRepositoryFirestoreImpl from '@/infrastructure/data-providers/leave-time-entries/LeaveTimeEntriesRepositoryFirestoreImpl';

export default class LeaveRequestsController {
  private holidaysRepository: HolidaysRepository;
  private leaveRequestsRepository: LeaveRequestRepository;
  private leaveTimeEntriesRepository: LeaveTimeEntriesRepository;
  private contactsRepository: ContactsRepository;
  private authenticationGateway: AuthenticationGateway;
  private calendarAdapter: CalendarAdapter;

  private createLeaveRequestUseCase: CreateLeaveRequestUseCase;
  private updateLeaveRequestUseCase: UpdateLeaveRequestUseCase;
  private verifyLeaveRequestUseCase: VerifyLeaveRequestUseCase;
  private getLeaveRequestUseCase: GetLeaveRequestUseCase;
  private getLeaveRequestsUseCase: GetLeaveRequestsUseCase;
  private getLeaveRangeUseCase: GetLeaveRangeUseCase;

  constructor() {
    this.leaveRequestsRepository = new LeaveRequestsRepositoryFirestoreImpl();
    this.leaveTimeEntriesRepository = new LeaveTimeEntriesRepositoryFirestoreImpl();

    this.contactsRepository = new ContactsRepositoryFirestoreImpl();
    this.holidaysRepository = new HolidaysRepositoryInMemoryImpl();
    this.authenticationGateway = new AuthenticationGatewayFirebaseImpl();
    this.calendarAdapter = new CalendarAdapterGoogleImpl();

    this.createLeaveRequestUseCase = new CreateLeaveRequestUseCase(this.leaveRequestsRepository, this.authenticationGateway);
    this.updateLeaveRequestUseCase = new UpdateLeaveRequestUseCase(this.leaveRequestsRepository, this.authenticationGateway);
    this.getLeaveRangeUseCase = new GetLeaveRangeUseCase({
      holidaysRepository: this.holidaysRepository,
    });
    this.getLeaveRequestUseCase = new GetLeaveRequestUseCase(
      this.leaveRequestsRepository,
      this.authenticationGateway,
      this.contactsRepository,
    );
    this.getLeaveRequestsUseCase = new GetLeaveRequestsUseCase({
      leaveRequestRepository: this.leaveRequestsRepository,
      authenticationGateway: this.authenticationGateway,
      contactsRepository: this.contactsRepository,
    });
    this.verifyLeaveRequestUseCase = new VerifyLeaveRequestUseCase({
      leaveRequestsRepository: this.leaveRequestsRepository,
      leaveTimeEntriesRepository: this.leaveTimeEntriesRepository,
      authenticationGateway: this.authenticationGateway,
      contactsRepository: this.contactsRepository,
      getLeaveRange: this.getLeaveRangeUseCase,
      calendarAdapter: this.calendarAdapter,
    });
  }

  createLeaveRequest(leaveRequest: LeaveRequest) {
    return this.createLeaveRequestUseCase
      .execute(leaveRequest)
      .then((leaveRequest) => leaveRequest)
      .catch((error) => {
        throw new Error(error.message);
      });
  }
  getLeaveRequest({ id }) {
    return this.getLeaveRequestUseCase
      .execute({ id })
      .then((leaveRequest) => leaveRequest)
      .catch((error) => {
        throw new Error(error.message);
      });
  }
  getLeaveRequests({ from, to }) {
    return this.getLeaveRequestsUseCase
      .execute({ from, to })
      .then((leaveRequests) => leaveRequests)
      .catch((error) => {
        throw new Error(error.message);
      });
  }
  updateLeaveRequest({ id, data }) {
    return this.updateLeaveRequestUseCase
      .execute({ id, data })
      .then((leaveRequest) => leaveRequest)
      .catch((error) => {
        throw new Error(error.message);
      });
  }
  getLeaveRange({ from, to }) {
    return this.getLeaveRangeUseCase.execute({ from, to });
  }
  verifyLeaveRequest({ id: leaveRequestId, status, verificationComments }) {
    return this.verifyLeaveRequestUseCase.execute({
      leaveRequestId,
      status,
      verificationComments,
    });
  }
}
