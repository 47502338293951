import { companyWebsite, serviceEmail } from '@/config/constants';
import firebase from 'firebase';

function capitalizeFirstLetter(value) {
  return value.charAt(0).toUpperCase() + value.slice(1);
}

function getFirestoreCollection(collectionName) {
  const firestore = firebase.firestore();
  return firestore.collection(collectionName);
}

const state = {
  navigationDrawer: true,
};

const getters = {
  // Gets a <file> event and returns some data
  getDataFromFileEvent: () => (event) => {
    return new Promise((resolve, reject) => {
      // Getting the file from the event
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);

      // Checking if it is a valid file type first
      const extension = file.name.split('.').pop();
      const allowedImageFormats = ['jpg', 'jpeg', 'heic', 'png', 'pdf'];
      if (!allowedImageFormats.includes(extension.toLowerCase())) {
        reject({
          error: 'Only the formats "JPG", "JPEG", "HEIC", "PNG" and "PDF"are allowed.',
        });
      }

      reader.onloadend = function (e) {
        const pictureDataId = new Date().getTime();

        const pictureData = {
          id: pictureDataId,
          fileName: file.name,
          data: e.target.result,
          file,
        };

        resolve(pictureData);
      };
    });
  },
  getEmployeeName: () => (contactID, contacts) => {
    if (!contactID) {
      return null;
    }

    const contact = contacts.find(({ id }) => contactID === id);
    return contact && contact.fullName;
  },
  dateErrors: () => (model) => {
    const errors = [];
    model.required === false && errors.push('Field is required');
    model.greaterThanCurrent === false && errors.push("Date can't be set before the current date.");
    return errors;
  },
  handlePicklist:
    () =>
    ({ text, id } = { text: null, id: null }) => ({ text, id }),
  getContactFromRecord:
    () =>
    ({ fullName, id } = { fullName: null, id: null }) => ({
      name: fullName,
      id,
    }),
  getOpportunityFromRecord:
    () =>
    (
      { opportunityRecordID, opportunityName } = {
        opportunityRecordID: null,
        opportunityName: null,
      },
    ) => ({ name: opportunityName, id: opportunityRecordID }),
  systemFields:
    () =>
    (id, { displayName, uid }, dateISOString) => {
      return {
        [id ? 'modifiedBy' : 'createdBy']: {
          name: displayName,
          id: uid,
        },
        [id ? 'modifiedDate' : 'createdDate']: dateISOString,
      };
    },
  getSpacesFromOpportunity: () => (opportunityID) => {
    const firestore = firebase.firestore();
    return firestore.collection('spaces').where('opportunityID', '==', opportunityID).orderBy('lineNumber').get();
  },
  getRecordsFromIdArray: (state, getters) => (collectionName, propertyName, idsArray) =>
    new Promise((resolve, reject) => {
      const firestore = firebase.firestore();
      const spacesCollection = firestore.collection(collectionName);

      const spacesReducer = (acc, querySnapshot) => [...acc, ...getters.querySnapshotToArray(querySnapshot)];

      const separatedSpacesIDSReducer = (size) => (acc, item) => {
        if (acc[acc.length - 1].length < size) {
          acc[acc.length - 1].push(item);
          return acc;
        } else {
          return [...acc, [item]];
        }
      };

      const requestBatches = idsArray.reduce(separatedSpacesIDSReducer(10), [[]]);

      Promise.all(requestBatches.map((batch) => spacesCollection.where(propertyName, 'in', batch).get()))
        .then((responses) => responses.reduce(spacesReducer, []))
        .then((spaces) => {
          const spacesSorter = ({ lineNumber: lineNumberA }, { lineNumber: lineNumberB }) => {
            return lineNumberA - lineNumberB;
          };

          resolve([...spaces].sort(spacesSorter));
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    }),
  getReportsByOpportunity:
    () =>
    (opportunityID, reportTypes = ['RFI', 'I']) =>
      new Promise((resolve, reject) => {
        if (!opportunityID) {
          reject({ error: 'Invalid or empty ID' });
        }
        const firestore = firebase.firestore();
        const reportsCollection = firestore.collection('fieldOperationsForms');
        const reportsRequest = reportsCollection.where('opportunity.id', '==', opportunityID).where('type.id', 'in', reportTypes);

        if (reportTypes.includes('RFI')) {
          const tasksCollection = firestore.collection('activities');
          const tasksRequest = tasksCollection.where('opportunity.id', '==', opportunityID).where('relatedRecordType', '==', 'RFI');

          let reports = [];
          const reportRequestPromise = reportsRequest
            .get()
            .then((querySnapshot) =>
              querySnapshot.forEach((doc) => {
                reports.push({
                  ...doc.data(),
                  id: doc.id,
                });
              }),
            )
            .catch((error) => reject(error));

          tasksRequest.get().then(async (querySnapshot) => {
            const tasks = [];
            querySnapshot.forEach((doc) => {
              tasks.push({
                ...doc.data(),
                id: doc.id,
              });
            });
            await reportRequestPromise;
            tasks.forEach((task) => {
              let parentReport = reports.find((report) => report.taskId === task.id);
              if (parentReport) {
                parentReport.assignee = task.assignee;
                parentReport.status = task.status;
                parentReport.dueDate = task.dueDateTime;
              }
            });
            resolve(reports);
          });
        } else {
          reportsRequest
            .get()
            .then((querySnapshot) => {
              const reports = [];
              querySnapshot.forEach((doc) => {
                reports.push({
                  ...doc.data(),
                  id: doc.id,
                });
              });
              resolve(reports);
            })
            .catch((error) => reject(error));
        }
      }),
  getCreateHTMLTemplate: () => (formObject, dataMapping, title) => {
    const objectProperties = ['createdBy', 'manager', 'assignee', 'opportunity', 'type'];

    const picklistProperties = ['issueType'];

    const arrayProperties = ['pictures', 'spaces', 'recordNumbers', 'lineNumbers'];

    const keys = dataMapping.map((item) => item[1]);

    let html = `<div style='font-family:Sans Serif; padding:10px;'>
            <p style='font-size:.875rem;'>A new ${title} was created for the "${formObject.opportunity.name}" opportunity, please see below for details.</p>
            <table style='table-layout: fixed; width: 80%'>
                <tbody>`;
    for (var i = 0; i < keys.length; i++) {
      if (i % 2 == 0) {
        background = '#ffffff';
      } else {
        var background = '#C9E2FF';
      }

      const variableToReadableQuestion = dataMapping.find((element) => element[1] === keys[i])[0];
      let value = formObject[keys[i]];
      if (objectProperties.includes(keys[i])) {
        value = formObject[keys[i]].name;
      }

      if (picklistProperties.includes(keys[i])) {
        value = formObject[keys[i]].text;
      }

      if (arrayProperties.includes(keys[i])) {
        if (keys[i] === 'pictures') {
          const dataToLink = formObject[keys[i]].map(({ url }) => `<img style="width: 70%; height: 280px;" src="${url}"></img>`);
          value = dataToLink.join('<br>');
        } else {
          value = formObject[keys[i]].join('<br>');
        }
      }

      var row = `
                        <tr style='background:${background};'>
                            <td valign='top' style='padding: 5px; font-size:.875rem; width:30%; text-align:left; word-wrap: break-word'>
                                <b>${variableToReadableQuestion}</b>
                            </td>
                            <td
                                valign='top'
                                style='
                                    font-size:.875rem;
                                    padding: 5px;
                                    width:70%;
                                    text-align:left;
                                    word-wrap: break-word'
                                colspan="2">${value || 'Not specified'}
                            </td>
                        </tr>`;
      html += row;
    }

    html += `
                </tbody>
            </table>
            <br>
            <p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:0pt;">
            <span style="font-size: 11pt; font-family: Calibri, sans-serif; font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;">Thanks!</span>
            </p>
            <br>
            <p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:0pt;">
            <span style="font-size: 11pt; font-family: Calibri, sans-serif; font-weight: 700; font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;">Customer Success Team</span>
            </p>
            <p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:0pt;">
            <span style="font-size: 11pt; font-family: Calibri, sans-serif; font-weight: 700; font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;">LightEdison</span>
            <span style="font-size: 11pt; font-family: Calibri, sans-serif; font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;">| </span>
            <span style="font-size: 11pt; font-family: Calibri, sans-serif; color: rgb(64, 156, 255); font-weight: 700; font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;">Brilliant.</span>
            </p>
            <p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:0pt;">
            <a href="mailto:${serviceEmail}" style="text-decoration-line: none;">
                <span style="font-size: 11pt; font-family: Calibri, sans-serif; color: rgb(17, 85, 204); font-variant-numeric: normal; font-variant-east-asian: normal; text-decoration-line: underline; text-decoration-skip-ink: none; vertical-align: baseline; white-space: pre-wrap;">${serviceEmail}</span>
            </a>
                <span style="font-size: 11pt; font-family: Calibri, sans-serif; font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;"> | 888.966.6377 | </span>
            <a href="${companyWebsite}" style="text-decoration-line: none;">
                <span style="font-size: 11pt; font-family: Calibri, sans-serif; color: rgb(17, 85, 204); font-variant-numeric: normal; font-variant-east-asian: normal; text-decoration-line: underline; text-decoration-skip-ink: none; vertical-align: baseline; white-space: pre-wrap;">lightedison.com</span>
            </a>
                <span style="font-size: 11pt; font-family: Calibri, sans-serif; font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;">&nbsp;</span>
            </p>
            <br>
            <p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:0pt;">
            <span style="font-size: 8pt; font-family: Calibri, sans-serif; font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;">CONFIDENTIAL NOTICE: The information contained in this email message may be privileged, confidential and protected from disclosure. If you are not the intended recipient, any dissemination, distribution or copying is strictly prohibited. If you think you have received this email message in error, please notify the sender by reply email and delete the message and any attachments.</span>
            </p>
            <div>
            <span style="font-size: 8pt; font-family: Calibri, sans-serif; font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;"><br></span>
            </div>
        </div>
        `;

    return html;
  },
  resetModel:
    (state, getters) =>
    (vuelidateModel = {}, referenceObject = {}, excludedProperties = []) => {
      const newObject = {};

      for (const key in vuelidateModel) {
        if (excludedProperties.includes(key) || !referenceObject.hasOwnProperty(key)) {
          if (Array.isArray(vuelidateModel[key])) {
            newObject[key] = [...vuelidateModel[key]];
          } else if (vuelidateModel[key] && typeof vuelidateModel[key] === 'object') {
            newObject[key] = getters.resetModel(vuelidateModel[key], vuelidateModel[key]);
          } else {
            newObject[key] = vuelidateModel[key];
          }

          continue;
        }

        if (Array.isArray(referenceObject[key])) {
          newObject[key] = [...referenceObject[key]];
        } else if (referenceObject[key] && typeof referenceObject[key] === 'object') {
          newObject[key] = getters.resetModel(referenceObject[key], referenceObject[key]);
        } else {
          newObject[key] = referenceObject[key];
        }
      }

      return newObject;
    },
  // The sort will be ascendent for now
  customStringSort:
    () =>
    (items = [], fieldName) => {
      return [...items].sort((a, b) => {
        const stringA = a[fieldName] ? a[fieldName].toLowerCase() : a[fieldName];
        const stringB = b[fieldName] ? b[fieldName].toLowerCase() : b[fieldName];

        if (stringA < stringB) {
          return -1;
        }

        if (stringA > stringB) {
          return 1;
        }

        return 0;
      });
    },
  getGoogleSheetChildFolder: (state, getters) => (parentFolderId, childFolderName, gapi) => {
    const sheetExportFolderId = getters['sheetExportFolderId'];
    const createGoogleDriveFolder = getters['createGoogleDriveFolder'];

    // Listing all the folder in Sheet Exports folder to see if the related folder exists
    const params = {
      q: `mimeType = 'application/vnd.google-apps.folder' and '${sheetExportFolderId}' in parents and trashed = false`,
    };

    return new Promise(async (resolve, reject) => {
      try {
        // Checking if the parent folder exist
        const sheetExportsExistingFolders = await gapi.client.drive.files.list(params);

        if (sheetExportsExistingFolders.status !== 200) {
          reject({ error: 'Drive Folder Not Found' });
          return;
        }

        const { files } = sheetExportsExistingFolders.result;

        if (files.some(({ name }) => name === childFolderName)) {
          const folder = files.find(({ name }) => name === childFolderName);
          console.log('Existing Folder');
          resolve(folder.id);
        } else {
          const folderCreationResult = await createGoogleDriveFolder(childFolderName, parentFolderId, gapi);
          console.log('New folder created');
          resolve(folderCreationResult.result.id);
        }
      } catch (error) {
        swal('Error!', "Something went wrong, please try again or contact with LightEdison's IT team", 'error');
        console.log(error);
        reject({ error });
      }
    });
  },
  // 'Sheet Exports' Google Drive's parent folder
  sheetExportFolderId: () => '1_BivYjSy_gBt_aRvUolcuW46j17gM60g',

  // Function to prevent propagation
  stopPropagation: () => (event) => {
    event.stopPropagation();
  },

  // Checking if a given date is inside a given date range (from and to)
  // IMPORTANT!!! All the three arguments need to be of type Date
  insideRange: () => (date, from, to) => date >= from && date <= to,

  // Curried function to take a document from a specific collection on firebase firestore
  firestoreDocument: () => (collectionName) => (documentId) => {
    const firestore = firebase.firestore();
    const collection = firestore.collection(collectionName);
    return documentId ? collection.doc(documentId) : collection.doc();
  },
  getQuarter: () => (d) => {
    d = d || new Date();
    var m = Math.floor(d.getMonth() / 3) + 1;
    return m > 4 ? m - 4 : m;
  },

  createGoogleDriveFolder: () => (folderName, parents, gapi) => {
    const params = {
      name: folderName,
      mimeType: 'application/vnd.google-apps.folder',
    };

    if (parents) {
      params.parents = [parents];
    }

    return gapi.client.drive.files.create({ resource: params });
  },
  getDrawer(state) {
    return state.navigationDrawer;
  },
  formatDate() {
    return function (value) {
      const date = new Date(value);
      return moment(date).format('M/D/YY');
    };
  },
  secondaryFormatDate() {
    return function (value) {
      const date = new Date(value);
      return moment(date).format('ddd, M/D/YY');
    };
  },
  formatTime() {
    return function (date) {
      const hours = date.getHours();
      const phase = hours > 11 ? 'PM' : 'AM';
      const minutes = date.getMinutes();
      const formatHour = hours % 12;
      const formattedHour = `${formatHour}:${minutes > 9 ? '' : 0}${minutes} ${phase}`;
      return formattedHour;
    };
  },

  // Capitalize first letter of a string
  capitalizeFirstLetter() {
    return function (value) {
      return capitalizeFirstLetter(value);
    };
  },

  // Padding a number with zeros or another character (1 to 0001 for instance)
  padNumber() {
    return function (n, width, z) {
      z = z || '0';
      n = n + '';
      return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
    };
  },

  // This function takes a vuelidate model object (modelObject variable)
  // And another object (referenceObject), the reference object is used to set the
  // properties of the model object.
  setObject() {
    return function (modelObject, referenceObject) {
      const returnedRecord = { ...modelObject };

      for (const key in referenceObject) {
        if (Object.keys(returnedRecord).includes(key)) {
          if (Array.isArray(returnedRecord[key])) {
            if (referenceObject[key]) {
              returnedRecord[key] = [...referenceObject[key]];
            }
          } else if (returnedRecord[key] && typeof returnedRecord[key] === 'object') {
            returnedRecord[key] = { ...referenceObject[key] };
          } else {
            returnedRecord[key] = referenceObject[key];
          }
        }
      }

      return returnedRecord;
    };
  },

  // Convert a formatted date string according to system fields,
  // into a date readable by the date input
  revertToInputDate() {
    return function (value) {
      const dateValues = value.split('/');
      const [month, day, year] = dateValues;
      return `20${year}-${month}-${day}`;
    };
  },

  // Convert formatted price string into a regular number
  revertCostImpact() {
    return (value) => value.replace(/[\,\$]/g, '').split('.')[0];
  },

  // Check if an element is an Array or an Array like Object
  // Resource: https://stackoverflow.com/questions/24048547/checking-if-an-object-is-array-like
  isArrayLike() {
    return function (item) {
      return (
        Array.isArray(item) ||
        (!!item && typeof item === 'object' && item.hasOwnProperty('length') && typeof item.length === 'number' && item.length >= 0)
      );
    };
  },
  // Custom base64 encoding
  // Resource: https://stackoverflow.com/questions/30106476/using-javascripts-atob-to-decode-base64-doesnt-properly-decode-utf-8-strings
  b64EncodeUnicode() {
    return function (str) {
      // first we use encodeURIComponent to get percent-encoded UTF-8,
      // then we convert the percent encodings into raw bytes which
      // can be fed into btoa.
      return btoa(
        encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function toSolidBytes(match, p1) {
          return String.fromCharCode('0x' + p1);
        }),
      );
    };
  },

  // This is for download the raw file from firebase storage
  // url: the image's download URL from firebase storage
  getBlobFromURL() {
    return function (url) {
      return new Promise((resolve, reject) => {
        // This can be downloaded directly:
        var xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.onload = function (event) {
          var blob = xhr.response;
          resolve(blob);
        };
        xhr.open('GET', url);
        xhr.send();
      });
    };
  },

  // Formats camelcase ('prepworkPictures' to 'Prep Work Pictures')
  camelCaseToName() {
    return function (value) {
      // Regular expression for CamelCase pattern
      const re = /[A-Z][a-z]+/g;

      // Retrieving the first word from variable
      let formattedWord;
      let match = re.exec(value);

      if (match) {
        formattedWord = capitalizeFirstLetter(value[0] + value.slice(1, match.index).toLowerCase());
      }

      // Retrieving the rest of the words
      const afterMatchs = value.match(re);
      for (let index = 0; index < afterMatchs.length; index++) {
        formattedWord += ' ' + capitalizeFirstLetter(afterMatchs[index]);
      }

      return formattedWord || value;
    };
  },

  // Custom email validator function, just in case
  customEmailValidation() {
    return function (email) {
      const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
      return regex.test(email);
    };
  },
  sendMessage() {
    return async function (gapi, from, to, subject, content) {
      // The Gmail API requires url safe Base64
      // (replace '+' with '-', and '/' with '_')

      const recipients = to.length > 1 ? to.join(', ') : to[0];

      const emailContent =
        'Content-Type: text/html; charset="UTF-8"\n' +
        'From: ' +
        from +
        '\r\n' +
        'To: ' +
        recipients +
        '\r\n' +
        'Subject: ' +
        subject +
        '\r\n\r\n' +
        content;

      const base64Data = this.b64EncodeUnicode(emailContent);
      const encodedEmail = base64Data.replace(/\+/g, '-').replace(/\//g, '_');

      try {
        const request = await gapi.client.gmail.users.messages.send({
          userId: 'me',
          resource: {
            raw: encodedEmail,
          },
        });
      } catch (error) {
        console.log(error);
      }
    };
  },
  // The name describes the function
  arrayItemsWithIndex() {
    return function (array) {
      if (!Array.isArray(array)) {
        return;
      }

      return array.map((item, index) => ({ ...item, index }));
    };
  },
  basicValidationModelFilter: () => (validationModel) => {
    const modelObject = {};
    const properties = Object.keys(validationModel.$params);

    for (const name of properties) {
      const value = validationModel[name].$model;

      if (Array.isArray(value)) {
        modelObject[name] = value;
      } else {
        modelObject[name] = typeof value === 'object' && value !== null ? { ...value } : value;
      }
    }

    return modelObject;
  },
  numberToCurrency: () => (number) => {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });
    return formatter.format(number);
  },
  // Getting form object from data validation model
  // IMPORTANT!. This validation model filter are for: issues, rfi, and punchlist records
  // daily report has its own validation model filter
  validationModelFilter() {
    return function (validationModel) {
      const modelObject = {};
      const properties = Object.keys(validationModel.$params);

      for (const name of properties) {
        if (name === 'cost_impact_ammount') {
          // Formatting the number to this format: $2,500.00
          const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',

            // These options are needed to round to whole numbers if that's what you want.
            //minimumFractionDigits: 0,
            //maximumFractionDigits: 0,
          });

          const formattedValue = formatter.format(validationModel[name].$model);
          modelObject[name] = formattedValue;

          continue;
        }
        const value = validationModel[name].$model;

        if (Array.isArray(value)) {
          modelObject[name] = value;
        } else {
          modelObject[name] = typeof value === 'object' && value !== null ? { ...value } : value;
        }
      }
      return modelObject;
    };
  },
  // Converts a query snapshot into an array (Only for arrays of records instead of a single record)
  querySnapshotToArray() {
    return function (querySnapshot) {
      const items = [];
      querySnapshot.forEach((doc) => {
        items.push({
          ...doc.data(),
          id: doc.id,
        });
      });

      return items;
    };
  },
  picklistFormatToArray: () => (items) => {
    return [...items].map((item) => {
      const [value] = Object.keys(item);

      return {
        text: item[value],
        id: value,
      };
    });
  },
  // Getter to retrieve an entire firebase collection if needed
  getAllRecords: () => (collectionName) => (collectionName ? getFirestoreCollection(collectionName).get() : null),
};

const mutations = {
  toggleDrawer(state, payload) {
    state.navigationDrawer = payload;
  },
  navButtonToggle(state) {
    state.navigationDrawer = !state.navigationDrawer;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
};
