import { Role } from '@/models/role';
import firebase from 'firebase';
import { FirestoreService } from '../data/firebase/db/firestoreService';
import { IFirestoreService } from '../data/firebase/db/iFirestoreService';
import { ServiceContainer } from '../serviceContainer';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Claims = { [key: string]: any };

export class UserService {
  private idToken: firebase.auth.IdTokenResult | undefined;

  private readonly firestoreService: IFirestoreService;

  constructor(firestoreService: IFirestoreService = ServiceContainer.get(FirestoreService)) {
    this.firestoreService = firestoreService;
  }

  public getCurrentUser(): firebase.User {
    return firebase.auth().currentUser!;
  }

  public async getIdToken(): Promise<firebase.auth.IdTokenResult> {
    if (!this.idToken) {
      this.idToken = await this.getCurrentUser().getIdTokenResult();
    }
    return this.idToken;
  }

  public async getClaims(): Promise<Claims> {
    return (await this.getIdToken()).claims;
  }

  public async getRoleId(): Promise<string> {
    return (await this.getClaims()).role;
  }

  public async getUserRole(): Promise<Role> {
    const roleId = await this.getRoleId();
    return this.firestoreService.getDocument<Role>('roles', roleId);
  }

  public async getRoleById(id: string): Promise<Role> {
    return this.firestoreService.getDocument<Role>('roles', id);
  }

  public async getAllRoles(): Promise<Role[]> {
    return this.firestoreService.getCollection<Role>('roles');
  }

  public subscribeToRoles(onUpdate: (roles: Role[]) => void): () => void {
    return this.firestoreService.subscribeToCollection('roles', onUpdate);
  }

  public deleteRole(roleId: string): Promise<void> {
    return this.firestoreService.deleteDocument('roles', roleId);
  }
}
