export class Deferred<T = void> {
  public promise: Promise<T>;

  private res: (value: T) => void;
  private rej: (error: Error) => void;

  constructor() {
    this.promise = new Promise<T>((res, rej) => {
      this.res = res;
      this.rej = rej;
    });
  }

  resolve(value: T) {
    this.res(value);
    return this.promise;
  }

  reject(error: Error) {
    this.rej(error);
    return this.promise;
  }

  static promise() {
    return new Deferred<void>();
  }

  static promiseOf<T>() {
    return new Deferred<T>();
  }
}
