import { SuperAdminRole } from '@/config/constants';
import { ServiceContainer } from '../serviceContainer';
import { UserService } from './userService';

export class PermissionsService {
  private readonly userService: UserService;

  private role: string = '';

  constructor(userService: UserService = ServiceContainer.get(UserService)) {
    this.userService = userService;
  }

  public async initialize(): Promise<void> {
    this.role = await this.userService.getRoleId();
  }

  public canPushAP(): boolean {
    return this.isSuperAdmin() || this.emailAllowed(['hl@lightedison.com']);
  }

  public isAccounting(): boolean {
    return this.isSuperAdmin() || this.emailAllowed(['hl@lightedison.com']);
  }

  public canViewAllTransactions(): boolean {
    return this.isSuperAdmin() || this.emailAllowed(['hl@lightedison.com', 'bvd@lightedison.com', 'cjs@lightedison.com']);
  }

  public canEditOrgChart(): boolean {
    return this.isSuperAdmin();
  }

  public canAccessReparentSpaces(): boolean {
    return this.isSuperAdmin() || this.emailAllowed(['mc@lightedison.com']);
  }

  public canAccessLaborCosting(): boolean {
    return this.isSuperAdmin() || this.emailAllowed(['mc@lightedison.com']);
  }

  public isManager(): boolean {
    return this.isSuperAdmin() || this.emailAllowed(['hl@lightedison.com']);
  }

  public canViewFundraisingReports(): boolean {
    return this.isSuperAdmin() || this.emailAllowed(['mc@lightedison.com', 'sms@lightedison.com']);
  }

  public canManageChecklistTemplates(): boolean {
    return this.isSuperAdmin() || this.emailAllowed(['mc@lightedison.com']);
  }

  public canManageContructionEvaluationTemplates(): boolean {
    return this.isSuperAdmin() || this.emailAllowed(['mc@lightedison.com', 'sms@lightedison.com']);
  }

  public canManageImprovementRequests(): boolean {
    return this.isSuperAdmin() || this.emailAllowed(['mc@lightedison.com']);
  }

  public canAccessOpportunityTimeDashboard(): boolean {
    return this.isSuperAdmin() || this.emailAllowed(['mc@lightedison.com', 'sms@lightedison.com']);
  }

  public canAccessMultipleContacts(): boolean {
    return this.isSuperAdmin() || this.emailAllowed(['hl@lightedison.com', 'ml@lightedison.com']);
  }

  public canExportTimesheets(): boolean {
    return (
      this.isSuperAdmin() ||
      this.emailAllowed([
        'mc@lightedison.com',
        'sms@lightedison.com',
        'hl@lightedison.com',
        'ab@lightedison.com',
        'ej@lightedison.com',
        'bac@lightedison.com',
        'ca@lightedison.com',
        'kl@lightedison.com',
        'cjs@lightedison.com',
      ])
    );
  }

  private emailAllowed(allowedList: string[]): boolean {
    const email = this.userService.getCurrentUser().email;
    return email ? allowedList.includes(email) : false;
  }

  private isSuperAdmin(): boolean {
    if (!this.role) throw new Error('PermissionsService is not initialized');
    return this.role === SuperAdminRole;
  }
}
